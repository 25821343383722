<template>
  <form-modal
    :title="_title"
    ref="formmodal"
    @save="handleSubmit"
    @cancel="cancel"
    size="sm"
  >
    <b-row>
      <b-col sm="12">
        <vue-phone-number-input
          v-model="form.phone"
          :default-country-code="_country_code"
          v-validate="`required`"
          data-vv-as="Phone Number"
          @update="selected.resultsVuePhoneNumberInput = $event"
          id="formPhoneNumber"
          name="formPhoneNumber"
        />
        <div
          id="helpId"
          class="form-text text-danger"
          v-if="errors.items.find((f) => f.field === 'formPhoneNumber')"
        >
          {{ errors.items.find((f) => f.field === "formPhoneNumber").msg }}
        </div>
      </b-col>

      <form-select
        label="Voice"
        v-model="selected.voice"
        v-validate="`required`"
        data-vv-as="Voice"
        :options="options.gender"
        :trackLable="`label`"
        findInFill="value"
        :col="12"
      />

      <!-- 
    ระบบเก่า 
   -->
      <form-select
        v-show="false"
        label="country code"
        v-model="selected.country_code"
        data-vv-as="country code"
        trackLable="_country_code"
        :col="3"
        url="/api/PhoneCountryData"
        ref="formSelect"
      />
      <form-input
        v-show="false"
        label="Phone Number"
        v-model="form.phone"
        data-vv-as="Phone Number"
        :col="4"
      />
    </b-row>
  </form-modal>
</template>
<script>
import VuePhoneNumberInput from "vue-phone-number-input"
import "vue-phone-number-input/dist/vue-phone-number-input.css"
export default {
  components: {
    VuePhoneNumberInput,
  },
  computed: {
    _country_code() {
      return this.selected.country_code.country_code
    },
    _title() {
      return this.form.id ? "Edit Phone Number" : `Add Phone Number`
    },
  },
  data() {
    return {
      selected: {
        resultsVuePhoneNumberInput: "",
        country_code: [],
        voice: [],
      },
      options: {
        gender: [
          { label: "Male voice", value: "m" },
          { label: "Female voice", value: "f" },
        ],
      },
      form: {
        id: "",
        phone: "",
        language: "",
        text: "",
        gender: "",
        user_id: "",
        phone_country_code_data_id: "",
        phone_toll: "",
      },
    }
  },
  methods: {
    show(data = null) {
      this.clearForm()
      if (data) {
        this.selected.voice = data.gender

        this.selected.country_code = data.phone_country_code_data_id
        this.form = data
      }
      this.switch("show")
    },
    async handleSubmit() {
      try {
        const validatetor = await this.$validator.validateAll()
        if (!validatetor) {
          this.alertValidate()
          return ""
        }

        const form = await this.beforeSubmit()

        /**
         * ตรวจสอบ form อีกรอบว่ารูปแบบของเบอร์ที่ป้อนเข้ามานั้นถูกต้องตามรูปแบบหรือไม่
         * หาข้อมูลเบอร์โทรไม่ตรงตามรูปแบบ form จะเป็น false
         * หากผ่านทุกเงื่อนไข form จะเป็น oject พร้อมส่งข้อมูลไปหลังบ้านทำงานต่อไป
         */
        if (!form) {
          return ""
        }
        const resp = await this.api.createOrUpdate(
          "/api/phoneData",
          form,
          "Phone Number"
        )
        this.clearForm()
        this.$emit("reloading")
      } catch (error) {
        console.log(error)
        this.alert.errorCode422(error)
        return ""
      }
    },
    beforeSubmit() {
      const _form = this.cp(this.form)

      const {
        country_code: { dial_code, id },
        voice: { value },
        resultsVuePhoneNumberInput: {
          phoneNumber,
          formattedNumber,
          countryCode,
          countryCallingCode,
          isValid,
          formatNational,
        },
      } = this.selected

      if (!isValid) {
        this.alert.validator({ text: "Invalid phone number" })
        return Promise.reject(isValid)
      }
      // set voice gender
      _form["gender"] = value

      // set Phone Conuntry
      /**
       * จัดรูปแบบข้อง ui ใหม่ แปลงเข้าเข้ากับ parameter ที่ต้องการของ ui เดิม
       */

      _form.phone_country_code_data_id = id
      _form.phone_toll = formattedNumber
      _form.phone = formatNational.replaceAll(" ", "")
      _form.phone_country_code_data_id =
        this.$refs.formSelect.optionSelect.find(
          (opt) =>
            opt.country_code == countryCode &&
            opt.dial_code == `+${countryCallingCode}`
        ).id

      /**
       * ตรวจสอบเบอร์โทร กรณีไม่ได้เติ่ม 0 เข้ามาข้างหน้า ถ้าไม่มี 0 ข้างหน้าจะไม่สามารถส่ง sms
       * ได้ดังนั้นต้องตรวจสอบ ว่ามี 0 นำหน้าหรือไม่
       */
      if (
        countryCallingCode === "66" &&
        countryCode === "TH" &&
        _form.phone.length === 9
      ) {
        _form.phone = `0${_form.phone}`
      }

      return _form
    },
    cancel() {
      this.clearForm()
    },
    clearForm() {
      this.$validator.reset()
      const form = this.form
      Object.keys(form).forEach((fill) => [(form[fill] = "")])
      this.switch("hide")
    },
    switch(type) {
      type === "show"
        ? this.$refs.formmodal.show()
        : this.$refs.formmodal.hide()
    },
  },
}
</script>
